.context {
  pointer-events: none;
  width: 100%;
  height: 100% !important;
  position: absolute;
  background-size: auto 100%;
}

.context h1 {
  text-align: center;
  color: #fff;
  font-size: 50px;
}

.area {
  width: 100%;

  height: 100% !important;

  background-size: auto 100%;
}

.circles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.circles li {
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: rgba(92, 83, 91, 0.2);
  /*animation: animate 25s linear infinite;*/
  bottom: -150px;
}

/* Apply different animations based on screen width */
@media (min-width: 768px) {
  .circles li {
    animation: animate-desktop 25s linear infinite;
  }
}

@media (max-width: 767px) {
  .circles li {
    animation: animate-mobile 25s linear infinite;
  }
}

.circles li:nth-child(1) {
  left: 25%;
  width: 80px;
  height: 80px;
  animation-delay: 0s;
}

.circles li:nth-child(2) {
  left: 10%;
  width: 20px;
  height: 20px;
  animation-delay: 2s;
  animation-duration: 12s;
}

.circles li:nth-child(3) {
  left: 70%;
  width: 20px;
  height: 20px;
  animation-delay: 4s;
}

.circles li:nth-child(4) {
  left: 40%;
  width: 60px;
  height: 60px;
  animation-delay: 0s;
  animation-duration: 18s;
}

.circles li:nth-child(5) {
  left: 65%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
}

.circles li:nth-child(6) {
  left: 75%;
  width: 110px;
  height: 110px;
  animation-delay: 3s;
}

.circles li:nth-child(7) {
  left: 35%;
  width: 150px;
  height: 150px;
  animation-delay: 7s;
}

.circles li:nth-child(8) {
  left: 50%;
  width: 25px;
  height: 25px;
  animation-delay: 15s;
  animation-duration: 45s;
}

.circles li:nth-child(9) {
  left: 20%;
  width: 15px;
  height: 15px;
  animation-delay: 2s;
  animation-duration: 35s;
}

.circles li:nth-child(10) {
  left: 85%;
  width: 150px;
  height: 150px;
  animation-delay: 0s;
  animation-duration: 11s;
}

@keyframes animate-desktop {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 0;
  }
  100% {
    transform: translateY(-1500px) rotate(720deg);
    opacity: 0;
    border-radius: 50%;
  }
}

@keyframes animate-mobile {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 0;
  }
  100% {
    transform: translateY(-1700px) rotate(720deg);
    opacity: 0;
    border-radius: 50%;
  }
}

.highlighted-word {
  text-decoration: none;
  background-position-y: -0%;
  background-image: linear-gradient(transparent 50%, #6e9db0 50%);
  transition: background 500ms ease;
  background-size: 2px;
  background-size: auto 175%;
}

.highlighted-word:hover {
  background-position-y: 100%;
}

.mail {
  color: inherit;
  text-decoration: none;
  border-bottom: 3px solid #6e9db0;
}
.wave {
  animation-name: wave-animation; /* Refers to the name of your @keyframes element below */
  animation-duration: 2.5s; /* Change to speed up or slow down */
  animation-iteration-count: infinite; /* Never stop waving :) */
  transform-origin: 70% 70%; /* Pivot around the bottom-left palm */
  display: inline-block;
}

@keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(14deg);
  } /* The following five values can be played with to make the waving more or less extreme */
  20% {
    transform: rotate(-8deg);
  }
  30% {
    transform: rotate(14deg);
  }
  40% {
    transform: rotate(-4deg);
  }
  50% {
    transform: rotate(10deg);
  }
  60% {
    transform: rotate(0deg);
  } /* Reset for the last half to pause */
  100% {
    transform: rotate(0deg);
  }
}
